<template>
  <div class="loader" :class="{'loader--active': visible}">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Loader',
  props: {
    visible: Boolean,
  },
};
</script>

<style lang="scss">
  @import "@/styles/variables";

  .loader {
    // display: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.8);
    opacity: 0;
    z-index: 10;
    transition: opacity 0.3s;
    pointer-events: none;

    &--active {
      display: block;
      opacity: 1;
      pointer-events: auto;
    }
  }
</style>
