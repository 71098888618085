function apiUrl(path, queryParams) {
  let url = `${process.env.VUE_APP_BASE_API}/${path}`;

  if (queryParams && Object.keys(queryParams).length > 0) {
    url = `${url}?${(new URLSearchParams(queryParams)).toString()}`;
  }

  return url;
}

export default apiUrl;
