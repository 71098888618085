export default {
  computed: {
    countries() {
      return {
        AU: this.$t('countries.AU'),
        AT: this.$t('countries.AT'),
        AZ: this.$t('countries.AZ'),
        AX: this.$t('countries.AX'),
        AL: this.$t('countries.AL'),
        DZ: this.$t('countries.DZ'),
        AS: this.$t('countries.AS'),
        AI: this.$t('countries.AI'),
        AO: this.$t('countries.AO'),
        AD: this.$t('countries.AD'),
        AQ: this.$t('countries.AQ'),
        AG: this.$t('countries.AG'),
        AR: this.$t('countries.AR'),
        AM: this.$t('countries.AM'),
        AW: this.$t('countries.AW'),
        AF: this.$t('countries.AF'),
        BS: this.$t('countries.BS'),
        BD: this.$t('countries.BD'),
        BB: this.$t('countries.BB'),
        BH: this.$t('countries.BH'),
        BY: this.$t('countries.BY'),
        BZ: this.$t('countries.BZ'),
        BE: this.$t('countries.BE'),
        BJ: this.$t('countries.BJ'),
        BM: this.$t('countries.BM'),
        BG: this.$t('countries.BG'),
        BO: this.$t('countries.BO'),
        BA: this.$t('countries.BA'),
        BW: this.$t('countries.BW'),
        BR: this.$t('countries.BR'),
        IO: this.$t('countries.IO'),
        VG: this.$t('countries.VG'),
        BN: this.$t('countries.BN'),
        BF: this.$t('countries.BF'),
        BI: this.$t('countries.BI'),
        BT: this.$t('countries.BT'),
        VU: this.$t('countries.VU'),
        VA: this.$t('countries.VA'),
        GB: this.$t('countries.GB'),
        HU: this.$t('countries.HU'),
        VE: this.$t('countries.VE'),
        VI: this.$t('countries.VI'),
        UM: this.$t('countries.UM'),
        QO: this.$t('countries.QO'),
        TL: this.$t('countries.TL'),
        VN: this.$t('countries.VN'),
        GA: this.$t('countries.GA'),
        HT: this.$t('countries.HT'),
        GY: this.$t('countries.GY'),
        GM: this.$t('countries.GM'),
        GH: this.$t('countries.GH'),
        GP: this.$t('countries.GP'),
        GT: this.$t('countries.GT'),
        GN: this.$t('countries.GN'),
        GW: this.$t('countries.GW'),
        DE: this.$t('countries.DE'),
        GG: this.$t('countries.GG'),
        GI: this.$t('countries.GI'),
        HN: this.$t('countries.HN'),
        HK: this.$t('countries.HK'),
        GD: this.$t('countries.GD'),
        GL: this.$t('countries.GL'),
        GR: this.$t('countries.GR'),
        GE: this.$t('countries.GE'),
        GU: this.$t('countries.GU'),
        DK: this.$t('countries.DK'),
        CD: this.$t('countries.CD'),
        JE: this.$t('countries.JE'),
        DJ: this.$t('countries.DJ'),
        DG: this.$t('countries.DG'),
        DM: this.$t('countries.DM'),
        DO: this.$t('countries.DO'),
        EU: this.$t('countries.EU'),
        EG: this.$t('countries.EG'),
        ZM: this.$t('countries.ZM'),
        EH: this.$t('countries.EH'),
        ZW: this.$t('countries.ZW'),
        IL: this.$t('countries.IL'),
        IN: this.$t('countries.IN'),
        ID: this.$t('countries.ID'),
        JO: this.$t('countries.JO'),
        IQ: this.$t('countries.IQ'),
        IR: this.$t('countries.IR'),
        IE: this.$t('countries.IE'),
        IS: this.$t('countries.IS'),
        ES: this.$t('countries.ES'),
        IT: this.$t('countries.IT'),
        YE: this.$t('countries.YE'),
        KZ: this.$t('countries.KZ'),
        KY: this.$t('countries.KY'),
        KH: this.$t('countries.KH'),
        CM: this.$t('countries.CM'),
        CA: this.$t('countries.CA'),
        IC: this.$t('countries.IC'),
        QA: this.$t('countries.QA'),
        KE: this.$t('countries.KE'),
        CY: this.$t('countries.CY'),
        KG: this.$t('countries.KG'),
        KI: this.$t('countries.KI'),
        CN: this.$t('countries.CN'),
        CC: this.$t('countries.CC'),
        CO: this.$t('countries.CO'),
        KM: this.$t('countries.KM'),
        CG: this.$t('countries.CG'),
        CR: this.$t('countries.CR'),
        CI: this.$t('countries.CI'),
        CU: this.$t('countries.CU'),
        KW: this.$t('countries.KW'),
        LA: this.$t('countries.LA'),
        LV: this.$t('countries.LV'),
        LS: this.$t('countries.LS'),
        LR: this.$t('countries.LR'),
        LB: this.$t('countries.LB'),
        LY: this.$t('countries.LY'),
        LT: this.$t('countries.LT'),
        LI: this.$t('countries.LI'),
        LU: this.$t('countries.LU'),
        MU: this.$t('countries.MU'),
        MR: this.$t('countries.MR'),
        MG: this.$t('countries.MG'),
        YT: this.$t('countries.YT'),
        MO: this.$t('countries.MO'),
        MK: this.$t('countries.MK'),
        MW: this.$t('countries.MW'),
        MY: this.$t('countries.MY'),
        ML: this.$t('countries.ML'),
        MV: this.$t('countries.MV'),
        MT: this.$t('countries.MT'),
        MA: this.$t('countries.MA'),
        MQ: this.$t('countries.MQ'),
        MH: this.$t('countries.MH'),
        MX: this.$t('countries.MX'),
        MZ: this.$t('countries.MZ'),
        MD: this.$t('countries.MD'),
        MC: this.$t('countries.MC'),
        MN: this.$t('countries.MN'),
        MS: this.$t('countries.MS'),
        MM: this.$t('countries.MM'),
        NA: this.$t('countries.NA'),
        NR: this.$t('countries.NR'),
        NP: this.$t('countries.NP'),
        NE: this.$t('countries.NE'),
        NG: this.$t('countries.NG'),
        AN: this.$t('countries.AN'),
        NL: this.$t('countries.NL'),
        NI: this.$t('countries.NI'),
        NU: this.$t('countries.NU'),
        NZ: this.$t('countries.NZ'),
        NC: this.$t('countries.NC'),
        NO: this.$t('countries.NO'),
        AE: this.$t('countries.AE'),
        OM: this.$t('countries.OM'),
        BV: this.$t('countries.BV'),
        AC: this.$t('countries.AC'),
        CP: this.$t('countries.CP'),
        IM: this.$t('countries.IM'),
        NF: this.$t('countries.NF'),
        CX: this.$t('countries.CX'),
        BL: this.$t('countries.BL'),
        MF: this.$t('countries.MF'),
        SH: this.$t('countries.SH'),
        CV: this.$t('countries.CV'),
        CK: this.$t('countries.CK'),
        TC: this.$t('countries.TC'),
        HM: this.$t('countries.HM'),
        PK: this.$t('countries.PK'),
        PW: this.$t('countries.PW'),
        PS: this.$t('countries.PS'),
        PA: this.$t('countries.PA'),
        PG: this.$t('countries.PG'),
        PY: this.$t('countries.PY'),
        PE: this.$t('countries.PE'),
        PN: this.$t('countries.PN'),
        PL: this.$t('countries.PL'),
        PT: this.$t('countries.PT'),
        PR: this.$t('countries.PR'),
        KR: this.$t('countries.KR'),
        RE: this.$t('countries.RE'),
        RU: this.$t('countries.RU'),
        RW: this.$t('countries.RW'),
        RO: this.$t('countries.RO'),
        SV: this.$t('countries.SV'),
        WS: this.$t('countries.WS'),
        SM: this.$t('countries.SM'),
        ST: this.$t('countries.ST'),
        SA: this.$t('countries.SA'),
        SZ: this.$t('countries.SZ'),
        SJ: this.$t('countries.SJ'),
        KP: this.$t('countries.KP'),
        MP: this.$t('countries.MP'),
        SC: this.$t('countries.SC'),
        PM: this.$t('countries.PM'),
        SN: this.$t('countries.SN'),
        VC: this.$t('countries.VC'),
        KN: this.$t('countries.KN'),
        LC: this.$t('countries.LC'),
        RS: this.$t('countries.RS'),
        CS: this.$t('countries.CS'),
        EA: this.$t('countries.EA'),
        SG: this.$t('countries.SG'),
        SY: this.$t('countries.SY'),
        SK: this.$t('countries.SK'),
        SI: this.$t('countries.SI'),
        SB: this.$t('countries.SB'),
        SO: this.$t('countries.SO'),
        SD: this.$t('countries.SD'),
        SR: this.$t('countries.SR'),
        US: this.$t('countries.US'),
        SL: this.$t('countries.SL'),
        TJ: this.$t('countries.TJ'),
        TH: this.$t('countries.TH'),
        TW: this.$t('countries.TW'),
        TZ: this.$t('countries.TZ'),
        TG: this.$t('countries.TG'),
        TK: this.$t('countries.TK'),
        TO: this.$t('countries.TO'),
        TT: this.$t('countries.TT'),
        TA: this.$t('countries.TA'),
        TV: this.$t('countries.TV'),
        TN: this.$t('countries.TN'),
        TM: this.$t('countries.TM'),
        TR: this.$t('countries.TR'),
        UG: this.$t('countries.UG'),
        UZ: this.$t('countries.UZ'),
        UA: this.$t('countries.UA'),
        WF: this.$t('countries.WF'),
        UY: this.$t('countries.UY'),
        FO: this.$t('countries.FO'),
        FM: this.$t('countries.FM'),
        FJ: this.$t('countries.FJ'),
        PH: this.$t('countries.PH'),
        FI: this.$t('countries.FI'),
        FK: this.$t('countries.FK'),
        FR: this.$t('countries.FR'),
        GF: this.$t('countries.GF'),
        PF: this.$t('countries.PF'),
        TF: this.$t('countries.TF'),
        HR: this.$t('countries.HR'),
        CF: this.$t('countries.CF'),
        TD: this.$t('countries.TD'),
        ME: this.$t('countries.ME'),
        CZ: this.$t('countries.CZ'),
        CL: this.$t('countries.CL'),
        CH: this.$t('countries.CH'),
        SE: this.$t('countries.SE'),
        LK: this.$t('countries.LK'),
        EC: this.$t('countries.EC'),
        GQ: this.$t('countries.GQ'),
        ER: this.$t('countries.ER'),
        EE: this.$t('countries.EE'),
        ET: this.$t('countries.ET'),
        ZA: this.$t('countries.ZA'),
        GS: this.$t('countries.GS'),
        JM: this.$t('countries.JM'),
        JP: this.$t('countries.JP'),
      };
    },
  },
};
