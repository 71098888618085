var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"adloox-dashboard"},[(_vm.data)?_c('vue-tabs',[_c('v-tab',{attrs:{"title":"% Fraud (SIVT + GIVT)"}},[(_vm.data.chart.length)?_c('div',{staticClass:"adloox-dashboard__chart"},[_c('apexchart',{attrs:{"type":"line","height":180,"options":_vm.chartOptions(
              _vm.findMin(_vm.data.chart, 'fraud'),
              _vm.findMax(_vm.data.chart, 'fraud')
            ),"series":_vm.chartDataFormatter('fraud', _vm.data.chart)}})],1):_vm._e(),_c('div',{staticClass:"adloox-dashboard__stats"},[_c('div',{staticClass:"adloox-dashboard__stats_left"},[_c('div',{staticClass:"row title-dimmed"},[_c('div',{staticClass:"col-4 pr-0"},[_vm._v("% Fraud (SIVT + GIVT)")]),_c('div',{staticClass:"col-8"},[_c('span',[_vm._v("% Fraud (SIVT + GIVT) ")]),_c('span',[_vm._v(_vm._s(_vm.$t("calendar.forTheLastXDays", [30])))])])]),_c('div',{staticClass:"row indicators"},[_c('div',{staticClass:"col-4 pr-0"},[_vm._v(" "+_vm._s(_vm.formatNumberCompact(_vm.data.last_period))+" % "),_c('img',{attrs:{"src":`/icons/${
                  _vm.data.last_period >= _vm.data.this_period
                    ? 'rise_red'
                    : 'downfall_green'
                }.svg`,"alt":"arrow"}})]),_c('div',{staticClass:"col-8"},[_vm._v(_vm._s(_vm.formatNumberCompact(_vm.data.this_period))+" %")])])]),_c('div',{staticClass:"adloox-dashboard__stats_right"},[_c('span',[_vm._v(_vm._s(_vm.$t('main.statistics')))]),_c('span',[_vm._v("Fraud (by Adloox)")])])])])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }