var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"login-page"},[_c('Modal',{attrs:{"shown":"","unclosable":""}},[_c('h2',{staticClass:"offset-3 col",attrs:{"slot":"header"},slot:"header"},[_vm._v(_vm._s(_vm.$t("auth.loginSystem")))]),_c('form',{attrs:{"slot":"body"},on:{"submit":function($event){$event.preventDefault();return _vm.submitForm()}},slot:"body"},[_c('div',{staticClass:"row pt-4 py-3"},[_c('div',{staticClass:"col-3 align-self-center form-label"},[_vm._v(" "+_vm._s(_vm.$t("auth.loginEmail"))+" ")]),_c('div',{staticClass:"col-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.login),expression:"login"}],staticClass:"form-input",class:{ 'form-input--is-invalid': _vm.$v.login.$error },attrs:{"type":"text","autocomplete":"username"},domProps:{"value":(_vm.login)},on:{"input":function($event){if($event.target.composing)return;_vm.login=$event.target.value}}})])]),_c('div',{staticClass:"row py-3"},[_c('div',{staticClass:"col-3 align-self-center form-label"},[_vm._v(" "+_vm._s(_vm.$t("auth.password"))+" ")]),_c('div',{staticClass:"col-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"form-input",class:{ 'form-input--is-invalid': _vm.$v.password.$error },attrs:{"type":"password","autocomplete":"current-password"},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing)return;_vm.password=$event.target.value}}})])]),_c('div',{staticClass:"row py-3"},[_c('div',{staticClass:"offset-3 col-9"},[_c('div',{staticClass:"d-flex"},[_c('button',{staticClass:"form-btn col-6",attrs:{"type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t("auth.enter"))+" ")]),(_vm.isVisible)?_c('router-link',{staticClass:"col-6 align-self-center d-inline-block modal-link",attrs:{"to":{
                name: 'password-reset',
                query: _vm.$route.query,
              }}},[_vm._v(" "+_vm._s(_vm.$t("auth.recoveryPassword"))+" ")]):_vm._e()],1)])]),_c('div',{staticClass:"row pt-3 pb-3"},[_c('section',{staticClass:"col offset-3 form-error-list"},[(
              (this.submitted && _vm.formErr.hasOwnProperty('login')) ||
              _vm.formErr.hasOwnProperty('password')
            )?_c('div',[_vm._v(" "+_vm._s(_vm.$t("validations.errorLoginOrPassword"))+" ")]):_vm._e(),(_vm.$v.$error && (!_vm.$v.login.required || !_vm.$v.password.required))?_c('div',[_vm._v(" "+_vm._s(_vm.$t("validations.enterCredentials"))+" ")]):_vm._e()])])]),(_vm.isVisible)?_c('div',{staticClass:"offset-3 col",attrs:{"slot":"footer"},slot:"footer"},[_vm._v(" "+_vm._s(_vm.$t("auth.noAccount"))+" "),_c('router-link',{staticClass:"col align-self-center modal-link",attrs:{"to":{ name: 'registration', query: _vm.$route.query }}},[_vm._v(" "+_vm._s(_vm.$t("auth.registration"))+" ")])],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }