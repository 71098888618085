var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cpm-and-bids"},[(_vm.data.cpm && _vm.data.bids)?_c('vue-tabs',_vm._l((_vm.tabs),function(tab){return _c('v-tab',{key:tab.key,attrs:{"title":tab.label}},[(_vm.data.cpm.chart.length || _vm.data.bids.chart.length)?_c('div',{staticStyle:{"height":"120px"}},[_c('apexchart',{attrs:{"type":"line","height":120,"options":_vm.chartOptions(
              _vm.findMin(_vm.data[tab.key].chart, tab.key),
              _vm.findMax(_vm.data[tab.key].chart, tab.key)
            ),"series":_vm.chartDataFormatter(tab.key, _vm.data[tab.key].chart)}})],1):_vm._e(),_c('div',{staticClass:"row cpm-and-bids__title-dimmed"},[_c('div',{staticClass:"col-6 pr-0"},[_vm._v(_vm._s(tab.label))]),_c('div',{staticClass:"col-6"},[_c('span',[_vm._v(_vm._s(tab.label))]),_c('span',[_vm._v(" "+_vm._s(_vm.$t('calendar.inXDays', [30])))])])]),_c('div',{staticClass:"row cpm-and-bids__stat"},[_c('div',{staticClass:"col-6 pr-0"},[_vm._v(" "+_vm._s(_vm.formatNumberCompact(_vm.data[tab.key].this_period))+" "+_vm._s(tab.suffix)+" "),_c('img',{attrs:{"src":`/icons/${
              _vm.data[tab.key].this_period >= _vm.data[tab.key].last_period
                ? 'rise'
                : 'downfall'
            }.svg`,"alt":"arrow"}})]),_c('div',{staticClass:"col-6"},[_vm._v(" "+_vm._s(_vm.formatNumberCompact(_vm.data[tab.key].last_period))+" "+_vm._s(tab.suffix)+" ")])]),_c('div',{staticClass:"pt-3 pb-1"},[_c('router-link',{attrs:{"to":"finances"}},[_c('span',[_vm._v(_vm._s(_vm.$t('main.statistics')))]),_c('span',[_vm._v(" "+_vm._s(tab.label))])])],1)])}),1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }