<template>
  <div class="data-grid" :class="{ 'data-grid--sortable': hasSortableFields }">
    <Loader :visible="detailedGrid.loading" />
    <table v-if="showTable" class="data-grid__table">
      <thead>
        <tr>
          <th
            v-for="key in Object.keys(renderModel)"
            :key="key"
            style="white-space: nowrap"
          >
            <span
              v-if="model[key].type === 'complex'"
              class="complex-field-header"
            >
              <CustomSelect
                type="select"
                :options="model[key].options"
                :selected.sync="selectedComplexCols[key]"
                v-on:update:selected="$emit('update:col', $event)"
              />
            </span>
            <span @click="setCurrentSortOrder(key)" v-else>
              {{ model[key].label }}
            </span>

            <span
              v-if="hasSortableFields && model[key].sortable"
              class="data-grid-sort"
            >
              <button
                @click="setCurrentSortOrder(key, 'asc')"
                :class="{
                  'data-grid-sort-btn--active': isCurrentSortOrder(key, 'asc'),
                }"
                class="data-grid-sort-btn data-grid-sort-btn--asc"
              />
              <button
                @click="setCurrentSortOrder(key, 'desc')"
                :class="{
                  'data-grid-sort-btn--active': isCurrentSortOrder(key, 'desc'),
                }"
                class="data-grid-sort-btn data-grid-sort-btn--desc"
              />
            </span>
          </th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(row, index) in gridData">
          <tr
            v-if="replaceRowWithExpander(index)"
            :key="`${index}-expander`"
            class="expander-wrapper"
          >
            <td :colspan="Object.keys(renderModel).length">
              <div
                class="expander ta-c"
                :class="{ 'expander-left': detailedExpansible }"
              >
                <button class="expander__button" @click="isExpansed = true">
                  {{ $t("main.showOthers") }}
                </button>
              </div>
            </td>
          </tr>
          <tr v-else :key="index">
            <td
              v-for="(key, indexCol) in Object.keys(renderModel)"
              :key="key"
              :style="{
                ...(renderModel[key].width && {
                  width: `${renderModel[key].width}px`,
                }),
                ...(countTotal &&
                  index === gridData.length - 1 && { fontWeight: 600 }),
              }"
              :class="{
                'data-grid__detailed-field':
                  renderModel[key].detailedQuery &&
                  (!countTotal || index !== gridData.length - 1),
                'data-grid__total-line-cell':
                  countTotal && index === gridData.length - 1,
                [fraudClass(cellData(row, key))]:
                  renderModel[key].type === 'percent-fraud',
              }"
            >
              <span
                @click="
                  renderModel[key].detailedQuery &&
                    detailedQuery(
                      selectedComplexCols[key].value.group,
                      (selectedComplexCols[key].value.detailedKey &&
                        row[selectedComplexCols[key].value.detailedKey]) ||
                        cellData(row, key),
                      cellData(row, key)
                    )
                "
              >
                <span
                  v-if="
                    countTotal &&
                    index === gridData.length - 1 &&
                    indexCol === 0
                  "
                >
                  {{ $t("common.total") }}
                </span>
                <span v-else-if="typeMatch(key, 'country')">
                  <span
                    v-if="cellData(row, key)"
                    class="flag-icon"
                    :class="`flag-icon-${cellData(row, key).toLowerCase()}`"
                  />
                  <span v-if="cellData(row, key)" class="cell-data">
                    {{ countries[cellData(row, key)] || cellData(row, key) }}
                  </span>
                  <span v-else class="cell-data">
                    <span class="worldwide-icon">
                      {{ $t("common.allWorld") }}
                    </span>
                  </span>
                </span>
                <span v-else-if="typeMatch(key, 'date')" class="cell-data">
                  {{ moment(cellData(row, key)).format("DD MMMM YYYY") }}
                </span>
                <span v-else-if="typeMatch(key, 'month')" class="cell-data">
                  {{ moment(cellData(row, key)).format("MMMM") }}
                </span>
                <span v-else-if="typeMatch(key, 'status')">
                  <span
                    class="status"
                    :class="{
                      'status--success': row[key] === 'Новая',
                      'status--warning': [
                        'Не оплачено',
                        'Частичная оплата',
                      ].includes(row[key]),
                    }"
                  >
                    {{ cellData(row, key) }}
                  </span>
                </span>
                <span v-else-if="typeMatch(key, 'link')" class="cell-data">
                  {{ cellData(row, key) }}
                </span>
                <span v-else-if="typeMatch(key, 'name')" class="cell-data">
                  {{ getName(row) }}
                </span>
                <span v-else>
                  {{ row[key] }}
                </span>
              </span>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
    <div class="data-grid--empty" v-else-if="!detailedGrid.shown && !loading">
      {{ $t("common.noDataAvailable") }}
    </div>
    <div v-if="detailedGrid.shown" style="overflow: hidden">
      <div class="row pt-3 pb-4">
        <h3 class="col">
          <span
            v-if="countries[detailedGrid.label]"
            class="flag-icon flag-icon--big"
            :class="`flag-icon-${detailedGrid.label.toLowerCase()}`"
          />
          <span
            v-if="!detailedGrid.label.length"
            class="worldwide-icon worldwide-icon--big"
          />
          {{
            countries[detailedGrid.label]
              ? countries[detailedGrid.label]
              : detailedGrid.label.length
              ? detailedGrid.label
              : $t('common.allWorld')
          }}
        </h3>
        <div class="col" style="text-align: right">
          <a @click="closeDetailedGrid()" href="javascript:void(0)">
            <span>&larr; </span>
            <span>{{ $t("common.return") }}</span>
          </a>
        </div>
      </div>
      <DataGrid
        v-if="detailedGrid.shown"
        :data="detailedGrid.data"
        :model="detailedGrid.model"
        v-on:update:col="
          detailedGrid.nested_group = $event.value.group;
          detailedQuery();
        "
        :expansible="detailedExpansible"
        :detailedExpansible="detailedExpansible"
        count-total
      />
    </div>
  </div>
</template>

<script>
import CustomSelect from '@/components/controls/CustomSelect.vue';
import Loader from '@/components/decorative/Loader.vue';
import countries from '@/strings/countries';
import moment from 'moment';

const expanderPreviewRows = 10;

export default {
  name: 'DataGrid',
  mixins: [countries],
  components: {
    CustomSelect,
    Loader,
  },
  props: {
    data: { type: Array, required: true },
    model: { type: Object, required: true },
    countTotal: Boolean,
    expansible: { type: Boolean, default: false },
    detailedExpansible: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    detailedRequestConfig: {},
  },
  data() {
    return {
      selectedComplexCols: {},
      expanderPreviewRows,
      sortOrder: 'desc',
      sortOrderBy: null,
      detailedGrid: {
        loading: false,
        shown: false,
        data: [],
        model: {},
        label: '',
        nested_group: null,
      },
      expansed: null,
    };
  },
  watch: {
    detailedRequestConfig() {
      if (this.detailedGrid.shown) this.detailedQuery();
    },
  },
  computed: {
    isExpansed: {
      get() {
        if (this.expansed !== null) return this.expansed;

        return !this.expansible || this.data.length < expanderPreviewRows + 2;
      },
      set(newVal) {
        this.expansed = newVal;
      },
    },
    hasSortableFields() {
      return (
        this.data.length >= 2
        && Object.values(this.model).findIndex(field => field.sortable) > -1
      );
    },
    showTable() {
      return (
        this.data.length > 0
        && !this.detailedGrid.shown
        && !(this.data.length === 1 && this.countTotal === true)
      );
    },
    sortedData() {
      if (!this.hasSortableFields || this.sortOrder === null) {
        return [...this.data];
      }
      const data = [...(this.countTotal ? this.data.slice(0, -1) : this.data)];
      const sortedData = this.sortOrder === 'asc'
        ? data.sort((a, b) => a[this.sortOrderBy] - b[this.sortOrderBy])
        : data.sort((a, b) => b[this.sortOrderBy] - a[this.sortOrderBy]);
      return this.countTotal
        ? [...sortedData, ...this.data.slice(-1)]
        : sortedData;
    },
    renderModel() {
      return Object.keys(this.model).reduce((acc, key) => {
        const val = this.model[key];
        const reducer = (accumulator, current) => [
          ...accumulator,
          current.value.field,
        ];
        const groupValues = Object.values(this.selectedComplexCols).reduce(
          reducer,
          [],
        );
        const condition = !groupValues.includes(val.hiddenForGroup);
        return { ...acc, ...(condition && { [key]: val }) };
      }, {});
    },
    gridData() {
      if (this.isExpansed) return this.sortedData;

      if (this.countTotal) {
        return [
          ...this.sortedData.slice(0, -1).slice(0, expanderPreviewRows - 1),
          ...this.sortedData.slice(-1),
        ];
      }

      return this.sortedData.slice(0, expanderPreviewRows - 1);
    },
  },
  created() {
    const keys = Object.keys(this.model);
    for (let i = 0; i < keys.length; i += 1) {
      if (
        this.model[keys[i]].type === 'complex'
        || this.model[keys[i]].type === 'name'
      ) {
        this.selectedComplexCols = {
          ...this.selectedComplexCols,
          ...{
            [keys[i]]: this.model[keys[i]].options[0],
          },
        };
      }
    }
  },
  methods: {
    moment,
    typeMatch(columnKey, desiredType) {
      return (
        this.model[columnKey].type === desiredType
        || (this.model[columnKey].type === 'complex'
          && this.selectedComplexCols[columnKey].value.type === desiredType)
      );
    },
    cellData(rowScope, columnKey) {
      return this.model[columnKey].type === 'complex'
        ? rowScope[this.selectedComplexCols[columnKey].value.field]
        : rowScope[columnKey];
    },
    getName(rowScope) {
      return rowScope.name === 0 ? rowScope.url : rowScope.name;
    },
    closeDetailedGrid() {
      this.detailedGrid = {
        loading: false,
        shown: false,
        data: [],
        model: {},
        label: '',
        nested_group: null,
      };
      this.$emit('update:detailShown', false);
    },
    detailedQuery(param, data, title) {
      const {
        start, end, group, url, detailedModels,
      } = this.detailedRequestConfig;
      this.detailedGrid.label = title || this.detailedGrid.label;
      this.detailedGrid.loading = true;
      this.detailedGrid.model = param
        ? detailedModels[param]
        : this.detailedGrid.model;
      this.detailedGrid.identifier = data || this.detailedGrid.identifier || 'unknown';
      this.detailedGrid.nested_group = this.detailedGrid.nested_group
        || (detailedModels[group].complex
          ? detailedModels[group].complex.options[0].value.field
          : this.selectedComplexCols.complex.value.setNestedGroup);

      // eslint-disable-next-line
      const { nested_group, identifier } = this.detailedGrid;
      this.$store
        .dispatch('commonRequest', {
          queryParams: {
            start,
            end,
            group,
            identifier,
            // eslint-disable-next-line
            ...(nested_group && { nested_group }),
          },
          url,
        })
        .then((resp) => {
          this.detailedGrid.data = resp;
          this.detailedGrid.shown = true;
          this.$emit('update:detailShown', true);
        })
        .catch(() => {
          this.detailedGrid.shown = false;
          this.$emit('update:detailShown', false);
        })
        .finally(() => {
          this.detailedGrid.loading = false;
        });
    },
    isCurrentSortOrder(key, order) {
      return this.sortOrder === order && this.sortOrderBy === key;
    },
    replaceRowWithExpander(index) {
      return (
        !this.isExpansed
        && index === this.gridData.length - (this.countTotal ? 2 : 1)
      );
    },
    setCurrentSortOrder(key, order) {
      if (this.sortOrderBy !== key) {
        this.sortOrder = order || 'desc';
        this.sortOrderBy = key;
        return;
      }

      const variants = ['desc', 'asc', null];
      const nextOrderVariant = variants[(variants.indexOf(this.sortOrder) + 1) % variants.length];
      this.sortOrder = this.sortOrder === order ? null : order || nextOrderVariant;
    },
    fraudClass(value) {
      if (value <= 15) {
        return 'data-grid__fraud_perfect';
      }
      if (value <= 30) {
        return 'data-grid__fraud_good';
      }
      if (value <= 50) {
        return 'data-grid__fraud_average';
      }
      if (value <= 70) {
        return 'data-grid__fraud_bad';
      }
      if (value > 70) {
        return 'data-grid__fraud_critical';
      }

      return '';
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/variables";

.flag-icon {
  height: 15px;

  &--big {
    height: 22px;
  }
}

.worldwide-icon {
  display: inline-block;
  width: 17px;
  height: 13px;
  margin-right: 5px;
  background-image: url(/icons/earth.svg);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;

  &--big {
    height: 22px;
    margin-bottom: -2px;
  }
}

.complex-field-header {
  padding-right: 20px;
}

.status {
  &--warning,
  &--success {
    position: relative;
    padding-left: 15px;

    &:before {
      position: absolute;
      content: "";
      width: 6px;
      height: 6px;
      border-radius: 6px;
      top: calc(50% - 4px);
      left: 0;
    }
  }

  &--warning:before {
    background-color: $c-warning;
  }

  &--success:before {
    background-color: $c-success;
  }
}

.data-grid {
  display: block;
  width: 100%;
  overflow-x: auto;

  &__fraud {
    &_perfect {
      background: $c-perfect-gradient;
    }

    &_good {
      background: $c-good-gradient;
    }

    &_average {
      background: $c-average-gradient;
    }

    &_bad {
      background: $c-bad-gradient;
    }

    &_critical {
      background: $c-bad-critical;
    }
  }

  &--sortable {
    th {
      user-select: none;
      cursor: pointer;
    }
  }

  &-sort {
    display: inline-block;
    vertical-align: baseline;
    margin-bottom: -2px;

    &-btn {
      display: block;
      margin-top: 3px;
      border: none;
      width: 7px;
      height: 5px;
      background-image: url(/icons/carret-light.svg);
      background-position: center;
      background-repeat: no-repeat;
      opacity: 0.4;

      &:hover {
        opacity: 0.9;
      }

      &--active {
        opacity: 1;
      }

      &--asc {
        transform: scaleY(-1);
      }
    }
  }

  .fw-600 {
    font-weight: 600;
  }

  &--empty {
    padding: 40px;
    color: $c-text-dimmed;
    text-align: center;
    text-transform: uppercase;
  }

  .flag-icon {
    border: 1px solid $c-border-light;
    margin-right: 5px;
  }

  &__total-line-cell {
    border-top: 2px solid $c-border-light;
  }

  &__detailed-field {
    color: $c-primary;

    .cell-data {
      text-decoration: underline;
      cursor: pointer;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &__table {
    //display: block;
    //overflow-x: hidden;
    width: 100%;
    padding-bottom: 15px;
    margin-bottom: 1rem;

    th {
      position: relative;
      text-align: left;
      font-size: 0.9rem;
      padding: 15px 5px 10px 5px;
      color: $c-text-dimmed;
      z-index: 1;
    }

    td {
      font-size: 1rem;
      padding: 8px 5px;
    }

    tr {
      &:nth-child(even) {
        background-color: $c-dropdown-bg;
      }
    }

    &--with-total {
      tbody {
        tr {
          span {
            display: block;
            height: 30px;
          }
        }

        td:last-child {
          font-weight: 600;
        }
      }
    }
  }

  .expander {
    display: block;
    text-align: center;
    position: relative;
    text-align: center;

    &-left {
      text-align: left;
    }

    &__button {
      padding: 1px 35px;
      font-size: 0.9rem;
      background-color: $c-dropdown-bg;
      border: 1px solid $c-border-extra-light;
      border-radius: 30px;
    }

    &-wrapper {
      height: 0;
      background-color: $c-white !important;
      border-top: 1px solid $c-border-light;
    }
  }
}
</style>
