const chartOptions = (minVal, maxVal) => {
  const max = maxVal + 0.4 * (maxVal - minVal);
  const min = minVal - 0.02 * (maxVal - minVal);

  return {
    chart: {
      width: '100%',
      height: '100%',
      toolbar: {
        tools: {
          download: false,
        },
      },
      sparkline: {
        enabled: true,
      },
    },
    stroke: {
      show: true,
      curve: 'smooth',
      colors: '#70ACC0',
      width: 2,
      dashArray: 0,
    },
    fill: {
      colors: ['#467fcf'],
      type: 'solid',
    },
    tooltip: {
      custom({
        series, seriesIndex, dataPointIndex,
      }) {
        return `
          <div class="payout-dashboard__tooltip">
            <span>${series[seriesIndex][dataPointIndex]}</span>
          </div>
        `;
      },
    },
    markers: {
      colors: '#70ACC0',
      fillOpacity: 0,
      strokeColors: '#70ACC0',
      hover: {
        sizeOffset: 3,
      },
    },
    grid: {
      show: true,
      borderColor: '#ddd',
      strokeDashArray: 2,
      row: {
        colors: ['#F9F9F9'],
      },
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    yaxis: {
      max,
      min,
    },
    xaxis: {
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      logarithmic: false,
    },
  };
};

export default chartOptions;
