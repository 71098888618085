<template>
  <div class="your-manager">
    <div class="your-manager__info">
      <div>{{ $t('main.yourManager') }}</div>
      <div class="your-manager__name">{{manager.name}}</div>
      <div class="your-manager__email">
        <a :href="`mailto:${manager.email}`">{{manager.email}}</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'YourManager',
  props: {
    manager: Object,
  },
};
</script>

<style lang="scss">
  @import "@/styles/variables";

  .your-manager {
    display: flex;
    line-height: 1.25;

    &__photo {
      width: 40px;
      height: 40px;
      background-color: #999;
      border-radius: 100px;
    }

    &__info {
      padding-left: 15px;
    }
  }
</style>
