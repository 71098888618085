<template>
  <div class="sidebar">
    <NavButton
      v-for="item in navList"
      :key="item.routeName"
      :link="item.routeName"
      :label="item.label"
      :type="item.type"
      :icon="item.icon"
    />
  </div>
</template>

<script>
import NavButton from '@/components/controls/NavButton.vue';

export default {
  name: 'Sidebar',
  components: {
    NavButton,
  },
  computed: {
    navList() {
      return [
        {
          routeName: '/',
          label: this.$t('main.main'),
          type: 'dashboard',
          icon: '',
        },
        {
          routeName: 'hosts',
          label: this.$t('main.hosts'),
          type: '',
          icon: '',
        },
        {
          routeName: 'finances',
          label: this.$t('main.finance'),
          type: '',
          icon: '',
        },
        {
          routeName: 'stats',
          label: this.$t('main.statistics'),
          type: '',
          icon: '',
        },
        {
          routeName: 'adloox',
          label: this.$t('main.audit'),
          type: '',
          icon: '',
        },
        {
          routeName: 'notifications',
          label: this.$t('main.notifications'),
          type: '',
          icon: '',
        },
        // {
        //   routeName: 'api', label: 'API', type: '', icon: '',
        // },
        {
          routeName: 'help',
          label: this.$t('main.help'),
          type: '',
          icon: 'help',
        },
      ].filter(({ routeName }) => !process.env.VUE_APP_DISABLED_ROUTE?.includes(routeName));
    },
  },
};
</script>
