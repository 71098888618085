<template>
  <div class="cpm-and-bids">
    <vue-tabs v-if="data.cpm && data.bids">
      <v-tab v-for="tab in tabs" :key="tab.key" :title="tab.label">
        <div
          v-if="data.cpm.chart.length || data.bids.chart.length"
          style="height: 120px"
        >
          <apexchart
            type="line"
            :height="120"
            :options="
              chartOptions(
                findMin(data[tab.key].chart, tab.key),
                findMax(data[tab.key].chart, tab.key)
              )
            "
            :series="chartDataFormatter(tab.key, data[tab.key].chart)"
          />
        </div>

        <div class="row cpm-and-bids__title-dimmed">
          <div class="col-6 pr-0">{{ tab.label }}</div>
          <div class="col-6">
            <span>{{ tab.label }}</span>
            <span> {{ $t('calendar.inXDays', [30]) }}</span>
          </div>
        </div>
        <div class="row cpm-and-bids__stat">
          <div class="col-6 pr-0">
            {{ formatNumberCompact(data[tab.key].this_period) }} {{ tab.suffix }}
            <img
              :src="`/icons/${
                data[tab.key].this_period >= data[tab.key].last_period
                  ? 'rise'
                  : 'downfall'
              }.svg`"
              alt="arrow"
            />
          </div>
          <div class="col-6">
            {{ formatNumberCompact(data[tab.key].last_period) }} {{ tab.suffix }}
          </div>
        </div>
        <div class="pt-3 pb-1">
          <router-link to="finances">
            <span>{{ $t('main.statistics') }}</span>
            <span> {{ tab.label }}</span>
          </router-link>
        </div>
      </v-tab>
    </vue-tabs>
  </div>
</template>

<script>
import { VueTabs, VTab } from 'vue-nav-tabs';
import chartOptions from '@/configs/chart-options/dashboardsChart';
import formatNumberCompact from '@/utils/numberFormat';

export default {
  name: 'CpmAndBids',
  components: {
    VueTabs,
    VTab,
  },
  props: {
    data: {},
  },
  methods: {
    formatNumberCompact,
    chartOptions,
    findMin(data, key) {
      return data.reduce(
        (acc, cur) => (cur[key] < acc ? cur[key] : acc),
        data[0][key],
      );
    },
    findMax(data, key) {
      return data.reduce((acc, cur) => (cur[key] > acc ? cur[key] : acc), 0);
    },
    chartDataFormatter(name, chartData) {
      const reducer = (acc, cur) => [...acc, cur[name]];
      const data = chartData.reduce(reducer, []);
      return [{ name, data }];
    },
  },
  computed: {
    tabs() {
      return [
        { key: 'cpm', label: 'CPM', suffix: this.$store.getters.getCurrency },
        { key: 'bids', label: this.$t('main.credits') },
      ].filter(el => this.data[el.key]);
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/variables";
.cpm-and-bids {
  &__title-dimmed {
    padding-top: 30px;
    color: $c-text-dimmed;
  }

  &__stat {
    font-size: 2.1rem;
    font-weight: 500;
    line-height: 1.2;

    img {
      height: 25px;
    }
  }
}
</style>
