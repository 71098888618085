const numberFormatCompact = new Intl.NumberFormat(
  'en-US',
  { notation: 'compact', compactDisplay: 'short' },
);

function formatNumberCompact(value) {
  return Math.abs(value) >= 10 ** 6 ? numberFormatCompact.format(value) : value;
}

export default formatNumberCompact;
