const chartOptions = {
  chart: {
    width: '100%',
    height: '100%',
    type: 'area',
    toolbar: {
      tools: {
        download: false,
      },
    },
    sparkline: {
      enabled: true,
    },
  },
  tooltip: {
    offsetX: 100,
    custom({
      series, seriesIndex, dataPointIndex,
    }) {
      return `
        <div class="payout-dashboard__tooltip payout-dashboard__tooltip--with-currency">
          <span>${series[seriesIndex][dataPointIndex]}</span>
        </div>
      `;
    },
  },
  markers: {
    colors: '#fff',
    fillOpacity: 0,
    strokeColors: '#000',
    hover: {
      sizeOffset: 3,
    },
  },
  stroke: {
    show: true,
    curve: 'smooth',
    colors: ['#999'],
    width: 2,
    dashArray: 0,
  },
  fill: {
    colors: ['#467fcf'],
    type: 'solid',
  },
  grid: {
    show: false,
  },
  dataLabels: {
    enabled: false,
  },
  xaxis: {
    crosshairs: {
      show: true,
    },
  },
};

export default chartOptions;
