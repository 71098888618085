<template>
  <div class="pane" :class="{'pane--small': small}">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Pane',
  props: {
    small: Boolean,
  },
};
</script>

<style lang="scss">
  @import "@/styles/variables";

  .pane {
    display: block;
    position: relative;
    height: 100%;
    padding: 30px 40px;
    background-color: $c-white;
    box-shadow: 0px 4px 20px rgba(4, 72, 122, 0.05);
    border-radius: 17px;
    // overflow: hidden;

    &--small {
      padding: 12px;
      border-radius: 10px
    }
  }
</style>
