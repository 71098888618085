import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';

import authModule from '@/store/modules/auth.module';
import hostsModule from '@/store/modules/hosts.module';
import statsModule from '@/store/modules/stats.module';
import adlooxModule from '@/store/modules/adloox.module';
import dashboardModule from '@/store/modules/dashboard.module';

import apiUrl from '@/utils/apiUrl';

Vue.use(Vuex);

export default new Vuex.Store({
  actions: {
    async commonRequest(_, { url, queryParams }) {
      return axios({
        url: apiUrl(url, queryParams),
        method: 'GET',
      })
        .then(({ data }) => data)
        .catch(({ data }) => {
          throw data;
        });
    },
  },
  modules: {
    authModule,
    hostsModule,
    statsModule,
    dashboardModule,
    adlooxModule,
  },
});
