<template>
  <div class="notifications-carousel">
    <carousel
      :perPage="3"
      :navigationEnabled="true"
      :navigationPrevLabel="''"
      :navigationNextLabel="''"
      :paginationEnabled="false"
    >
      <slide
        v-for="notification in $store.getters.notifications"
        :key="notification.id"
        class="colored-notification"
        @slide-click="go()"
        :class="{
          'colored-notification--success': ['info',].includes(notification.level),
          'colored-notification--danger': ['danger', 'warning'].includes(notification.level),
        }"
      >
        {{ notification.title }}
      </slide>
    </carousel>
  </div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';

export default {
  name: 'NotificationsCarousel',
  components: {
    Carousel,
    Slide,
  },
  methods: {
    go() {
      if (this.$route.name !== 'notifications') this.$router.push({ name: 'notifications' });
    },
  },
};
</script>

<style lang="scss">
  @import "@/styles/variables";

  .notifications-carousel {
    margin: 20px 0;
  }

  .colored-notification {
    position: relative;
    background: linear-gradient(90deg, $c-text-regular 0%, $c-text-regular 40%, $c-text-regular);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    color: $c-text-regular;
    padding: 0 10px 0 25px;
    cursor: pointer;

    &--success {
      background: linear-gradient(90deg, $c-success 0%, $c-text-regular 40%, $c-text-regular);
      background-clip: text;

      &:before {
        background-image: url(/icons/bell.svg);
      }
    }

    &--danger {
      background: linear-gradient(90deg, $c-danger 0%, $c-text-regular 40%, $c-text-regular);
      background-clip: text;

      &:before {
        background-image: url(/icons/danger.svg);
      }
    }

    &:before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 15px;
      height: 15px;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  // override plugin styles

  .VueCarousel-navigation--disabled.VueCarousel-navigation--disabled {
    opacity: 0;
  }

  .VueCarousel-navigation-button {
    background-repeat: no-repeat;
    background-position: center;
  }

  .VueCarousel-navigation-prev {
    background-position: 0 50%;
    background-image: url(/icons/arr-left.svg);
  }

  .VueCarousel-navigation-next {
    background-position: 100% 50%;
    background-image: url(/icons/arr-right.svg);
  }
</style>
