<template>
  <div class="adloox-dashboard">
    <vue-tabs v-if="data">
      <v-tab title="% Fraud (SIVT + GIVT)">
        <div v-if="data.chart.length" class="adloox-dashboard__chart">
          <apexchart
            type="line"
            :height="180"
            :options="
              chartOptions(
                findMin(data.chart, 'fraud'),
                findMax(data.chart, 'fraud')
              )
            "
            :series="chartDataFormatter('fraud', data.chart)"
          />
        </div>
        <div class="adloox-dashboard__stats">
          <div class="adloox-dashboard__stats_left">
            <div class="row title-dimmed">
              <div class="col-4 pr-0">% Fraud (SIVT + GIVT)</div>
              <div class="col-8">
                <span>% Fraud (SIVT + GIVT) </span>
                <span>{{ $t("calendar.forTheLastXDays", [30]) }}</span>
              </div>
            </div>
            <div class="row indicators">
              <div class="col-4 pr-0">
                {{ formatNumberCompact(data.last_period) }} %
                <img
                  :src="`/icons/${
                    data.last_period >= data.this_period
                      ? 'rise_red'
                      : 'downfall_green'
                  }.svg`"
                  alt="arrow"
                />
              </div>
              <div class="col-8">{{ formatNumberCompact(data.this_period) }} %</div>
            </div>
          </div>
          <div class="adloox-dashboard__stats_right">
            <span>{{ $t('main.statistics') }}</span><span>Fraud (by Adloox)</span>
          </div>
        </div>
      </v-tab>
    </vue-tabs>
  </div>
</template>

<script>
import { VTab, VueTabs } from 'vue-nav-tabs';
import chartOptions from '@/configs/chart-options/dashboardsChart';
import formatNumberCompact from '@/utils/numberFormat';

export default {
  name: 'Adloox',
  components: {
    VueTabs,
    VTab,
  },
  props: {
    data: {
      type: Object,
      default: () => ({
        chart: [],
      }),
    },
  },
  methods: {
    formatNumberCompact,
    chartOptions,
    findMin(data, key) {
      return data.reduce(
        (acc, cur) => (cur[key] < acc ? cur[key] : acc),
        data[0][key],
      );
    },
    findMax(data, key) {
      return data.reduce((acc, cur) => (cur[key] > acc ? cur[key] : acc), 0);
    },
    chartDataFormatter(name, chartData) {
      return [{ name, data: chartData.map(d => d[name]) }];
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.adloox-dashboard {
  display: flex;
  flex-direction: column;

  &__chart {
    height: 180px;
  }

  &__stats {
    padding-top: 30px;
    display: flex;
    justify-content: space-between;

    &_left {
      width: 60%;

      .title-dimmed {
        color: $c-text-dimmed;
        font-size: 12px;
      }

      .indicators {
        padding-top: 5px;
        font-size: 2.1rem;
        font-weight: 500;
        line-height: 1.2;

        img {
          height: 25px;
        }
      }
    }

    &_right {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
  }
}
</style>
