import Vue from 'vue';
import VueI18n from 'vue-i18n';

import ru from './messages/ru.json';
import en from './messages/en.json';

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'ru',
  messages: {
    en,
    ru,
  },
});


export default i18n;
