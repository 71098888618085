import moment from 'moment';
import substractDays from '@/utils/substractDays';

const end = moment().format('YYYY-MM-DD'); // today
const endYesterday = moment(new Date(substractDays(1).setHours(0, 0, 0, 0))).format('YYYY-MM-DD'); // yesterday
const start = moment(substractDays(+process.env.VUE_APP_DASHBOARD_DAYS)).format('YYYY-MM-DD'); // thirtyDaysAgo

const dashboardConfig = {
  selectedRange: {
    value: {
      start,
      end,
    },
    label: 'Месяц',
  },
  dashboardFinanceConfig: {
    start,
    end,
    months_count: 3,
  },
  dashboardEarningsConfig: [
    {
      start,
      end,
      metric: 'cpm',
      this_period_day: 30,
      last_period_day: 30,
    },
    {
      start,
      end,
      metric: 'bids',
      this_period_day: 30,
      last_period_day: 30,
    },
  ],
  dashboardAdlooxConfig: {
    start,
    end: endYesterday,
    this_period_day: 30,
    last_period_day: 30,
  },
};

export default dashboardConfig;
