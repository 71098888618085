<template>
  <footer class="footer col-12 py-4">
    <div class="row">
          <div class="col-2">
            <img src="/icons/logo-dimmed.svg" alt="Utraff.com" />
          </div>
          <div v-if="contacts.email || contacts.telegramLink" class="col">
            <div>Контакты UMG team:</div>
            <div v-if="contacts.email">
              Email:
              <a :href="`mailto:${contacts.email}`"
                >{{contacts.email}}</a
              >
            </div>
            <div v-if="contacts.telegramLink">
              Telegram:
              <a :href="contacts.telegramLink" target="_blank"
                >{{ contacts.telegram }}</a
              >
            </div>
          </div>
          <div class="col-2 ml-auto">
            <a href="http://utraff.com/static/pdf/rules.pdf" target="_blank">
              <span>{{ $t("common.conditions") }}</span>
              <br />
              <span>{{ $t("main.forPartners") }}</span>
            </a>
          </div>
          <div class="col-2">
            <a href="http://utraff.com/static/pdf/offer.pdf" target="_blank">
              <span>{{ $t("common.offer") }}</span>
              <br />
              <span>{{ $t("main.forPartners") }}</span>
            </a>
          </div>
          <div class="col-3 support">
            <a
              :href="`mailto:${email}?subject=Поддержка пользователя ${$store.getters.login}`"
            >
              <span>{{ $t("main.technicalSupport") }}</span>
              <br />
              <span>{{ email }}</span>
            </a>
          </div>
        </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  data() {
    return {
      email: 'partners@uniontraff.com',
    };
  },
  computed: {
    contacts() {
      return {
        email: process.env.VUE_APP_CONTACT_EMAIL,
        telegram: process.env.VUE_APP_CONTACT_TELEGRAM,
        telegramLink: process.env.VUE_APP_CONTACT_TELEGRAM_LINK,
      };
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/variables";

.footer {
  border-top: 1px solid $c-border-light;

  .support {
    display: flex;
    justify-content: flex-end;

    a {
      position: relative;
      display: inline-block;

      &:before {
        content: "";
        display: block;
        position: absolute;
        right: calc(100% + 6px);
        top: 3px;
        background-color: $c-dropdown-border-active;
        width: 10px;
        height: 10px;
        border-radius: 10px;
      }
    }
  }

  a {
    color: $c-dropdown-border-active;
    text-decoration: none;
    line-height: 1.4;

    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
