<template>
  <button class="custom-button"
    :class="{
      'custom-button--big': type === 'big',
      'custom-button--common': type === 'common',
      'custom-button--with-icon': icon,
    }"
    @click="$emit('click')"
  >
    <span v-if="icon" class="custom-button__icon">
      <img :src="iconSrc" :alt="icon">
    </span>
    <span>{{label}}</span>
  </button>
</template>

<script>
export default {
  name: 'CustomButton',
  props: {
    label: String,
    type: {
      type: String,
      default: 'big',
    },
    icon: String,
  },
  computed: {
    iconSrc() {
      return `/icons/${this.icon}.svg`;
    },
  },
  methods: {
  },
};
</script>

<style lang="scss">
  @import "@/styles/variables";

  .custom-button {
    position: relative;
    cursor: pointer;
    border: none;

    &--big {
      position: relative;
      padding: 15px 20px 15px 55px;
      font-size: 14px;
      font-weight: 600;
      line-height: 1.1rem;
      color: $c-white;
      background-color: $c-primary;
      border-radius: 100px;

      .custom-button__icon {
        position: absolute;
        left: 9px;
        top: 50%;
        transform: translateY(-50%);
        width: 30px;
        height: 30px;
        text-align: center;
        color: $c-primary;
        background-color: $c-white;
        border-radius: 50%;

        img {
          position: absolute;
          left: calc(15px / 2);
          top: 50%;
          transform: translateY(-50%);
          width: 15px;
          height: 15px;
        }
      }

      &:hover {
        box-shadow: 0 0 10px rgba(4, 72, 122, 0.05);
      }
    }

    &--common {
      display: inline-block;
      border: none;
      background-color: $c-button-common;
      padding: 6px 20px;
      border-radius: 100px;

      &.custom-button--with-icon {
        padding: 6px 20px 6px 35px;
      }

      .custom-button__icon {
        position: absolute;
        top: 7px;
        left: 14px;
      }

      &:hover {
        box-shadow: 0 0 10px rgba(4, 72, 122, 0.05);
      }
    }

    & + & {
      margin-left: 12px;
    }
  }
</style>
