/**
* Returns a Date shifted back a certain number of days
* @param <Number>days - Number of days to shift
* @return <Object.Date> - Shifted date
*/
function substractDays(days) {
  const date = new Date();

  return new Date(date.getTime() - (days * 24 * 60 * 60 * 1000));
}

export default substractDays;
