import axios from 'axios';
import apiUrl from '@/utils/apiUrl';

const initialState = () => ({
  adlooxData: [],
});

const adlooxModule = {
  state: initialState(),
  getters: {
    getAdlooxData: state => state.adlooxData,
  },
  mutations: {
    set(state, { key, data }) {
      state[key] = data;
    },
    resetStatsState(state) {
      Object.assign(state, initialState());
    },
  },
  actions: {
    async adlooxRequest({ commit }, payload) {
      return axios({
        url: apiUrl('adloox/', payload.queryParams),
        method: 'GET',
      })
        .then(({ data }) => {
          commit('set', { key: 'adlooxData', data });
          return data;
        })
        .catch((err) => {
          throw err.data;
        });
    },
  },
};

export default adlooxModule;
