import Vue from 'vue';
import VueRouter from 'vue-router';

// navigation guards
import ifNotAuthenticated from './nav-guards/ifNotAuthenticated';
import ifAuthenticated from './nav-guards/ifAuthenticated';

// critical prefetched views
import Dashboard from '../views/Dashboard.vue';
import Login from '../views/Login.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'dashboard',
    component: Dashboard,
    meta: { enableLayout: true },
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/hosts',
    name: 'hosts',
    component: () => import(/* webpackChunkName: "hosts" */ '../views/Hosts.vue'),
    meta: { enableLayout: true },
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/notifications',
    name: 'notifications',
    component: () => import(/* webpackChunkName: "notifications" */ '../views/Notifications.vue'),
    meta: { enableLayout: true },
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/help',
    name: 'help',
    component: () => import(/* webpackChunkName: "help" */ '../views/Help.vue'),
    meta: { enableLayout: true },
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/stats',
    name: 'stats',
    component: () => import(/* webpackChunkName: "stats" */ '../views/Stats.vue'),
    meta: { enableLayout: true },
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/finances',
    name: 'finances',
    component: () => import(/* webpackChunkName: "finances" */ '../views/Finances.vue'),
    meta: { enableLayout: true },
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/adloox',
    name: 'adloox',
    component: () => import(/* webpackChunkName: "finances" */ '../views/Adloox.vue'),
    meta: { enableLayout: true },
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/profile-edit',
    name: 'profile-edit',
    component: () => import(/* webpackChunkName: "profile-edit" */ '../views/ProfileEdit.vue'),
    meta: { enableLayout: true },
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/payouts',
    name: 'payouts',
    component: () => import(/* webpackChunkName: "payouts" */ '../views/Payouts.vue'),
    meta: { enableLayout: true },
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: '/registration',
    name: 'registration',
    component: () => import(/* webpackChunkName: "registration" */ '../views/Register.vue'),
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: '/password-reset',
    name: 'password-reset',
    component: () => import(/* webpackChunkName: "password-reset" */ '../views/PasswordReset.vue'),
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: '/important-updates',
    name: 'important-updates',
    component: () => import(/* webpackChunkName: "show-stopper" */ '@/views/ShowStopper.vue'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '*',
    redirect: '/',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
