import axios from 'axios';
import apiUrl from '@/utils/apiUrl';
import currencyModel from '@/configs/currencyModel';

const LS_LANGUAGE_KEY = 'cp_language';

const initialState = () => ({
  status: '',
  login: localStorage.getItem('login') || '',
  token: localStorage.getItem('token') || '',
  manager: JSON.parse(localStorage.getItem('manager')) || null,
  notifications: [],
  recentRulesAccepted: null,
  language: localStorage.getItem(LS_LANGUAGE_KEY) || navigator.language || 'en',
  currency: '',
});

const authModule = {
  state: initialState(),
  getters: {
    getCurrency: state => currencyModel[state.currency.iso_code],
    isAuthenticated: state => !!state.login,
    login: state => state.login,
    manager: state => state.manager,
    notifications: state => state.notifications,
    getRecentRulesAccepted: state => state.recentRulesAccepted,
    language: state => state.language,
  },
  mutations: {
    SET_STATUS(state, payload) {
      state.status = payload;
    },
    SET_LANGUAGE(state, payload) {
      if (payload) {
        localStorage.setItem(LS_LANGUAGE_KEY, payload);
        state.language = payload;
      }
    },
    authSuccess(state, { token, login }) {
      localStorage.setItem('token', token);
      localStorage.setItem('login', login);

      axios.defaults.headers.common.Authorization = token;

      state.status = 'success';
      state.token = token;
      state.login = login;
    },
    LOGOUT(state) {
      localStorage.removeItem('token');
      localStorage.removeItem('login');
      localStorage.removeItem('manager');

      axios.defaults.headers.common.Authorization = '';

      state.status = '';
      state.token = null;
      state.login = null;
      state.notifications = [];
      state.recentRulesAccepted = null;
    },
    setManager(state, manager) {
      localStorage.setItem('manager', JSON.stringify(manager));
      state.manager = manager;
    },
    setRecentRulesAccepted(state, payload) {
      state.recentRulesAccepted = payload;
    },
    setNotifications(state, payload) {
      state.notifications = payload;
    },
    setCurrency(state, payload) {
      state.currency = payload.find(c => c.default);
    },
  },
  actions: {
    async managerRequest({ commit }) {
      return axios({
        url: apiUrl('manager/'),
        method: 'GET',
      }).then(({ data }) => {
        commit('setManager', data);
      });
    },
    async currencyRequest({ commit }) {
      return axios({
        url: apiUrl('currency/'),
        method: 'GET',
      }).then(({ data }) => {
        commit('setCurrency', data);
      });
    },
    async authRequest({ dispatch, commit }, payload) {
      commit('resetHostsState');
      commit('resetStatsState');
      commit('resetDashboardState');
      commit('SET_STATUS', 'loading');

      return axios({
        url: apiUrl(`auth/${payload.action}/`),
        data: payload.user,
        method: payload.method || 'POST',
      })
        .then(async (resp) => {
          const token = `Token ${resp.data.token}`;
          axios.defaults.headers.common.Authorization = token;

          await axios({
            url: apiUrl('auth/me/'),
            method: 'GET',
          })
            .then((response) => {
              const { login, language } = response.data;
              commit('authSuccess', { token, login });
              commit('SET_LANGUAGE', language);
            })
            .catch((err) => {
              commit('SET_STATUS', 'error');
              localStorage.removeItem('token');
              localStorage.removeItem('login');
              throw err.response.data.error.message;
            });

          // load manager info
          dispatch('managerRequest');

          // load notifications
          dispatch('notificationsRequest');

          dispatch('currencyRequest');
        })
        .catch((err) => {
          commit('SET_STATUS', 'error');
          localStorage.removeItem('token');
          localStorage.removeItem('login');
          throw err.response ? err.response.data.error.message : err;
        });
    },
    async authToken({ dispatch, commit }, payload) {
      commit('resetHostsState');
      commit('resetStatsState');
      commit('resetDashboardState');
      commit('SET_STATUS', 'loading');

      const { token } = payload;
      axios.defaults.headers.common.Authorization = token;

      return axios({
        url: apiUrl('auth/me/'),
        method: 'GET',
      })
        .then((response) => {
          const { login, language } = response.data;
          commit('authSuccess', { token, login });
          commit('SET_LANGUAGE', language);

          // load manager info
          dispatch('managerRequest');

          // load notifications
          dispatch('notificationsRequest');

          dispatch('currencyRequest');
        })
        .catch((err) => {
          commit('SET_STATUS', 'error');
          localStorage.removeItem('token');
          localStorage.removeItem('login');
          throw err.response ? err.response.data.error.message : err;
        });
    },
    async authDataRequest({ commit }, { data, action, method }) {
      return axios({
        url: apiUrl(`auth/${action}/`),
        data,
        method: method || 'GET',
      })
        .then((response) => {
          const { language } = response.data;
          commit('SET_LANGUAGE', language);

          return response.data;
        })
        .catch((err) => {
          throw err.data.error.message;
        });
    },
    async notificationsRequest({ commit }) {
      return axios({
        url: apiUrl('notification/'),
        method: 'GET',
      }).then(({ data }) => {
        commit('setNotifications', data);
        return data;
      });
    },
    logoutRequest({ commit }) {
      commit('LOGOUT');
    },
  },
};

export default authModule;
