import axios from 'axios';
import apiUrl from '@/utils/apiUrl';

const initialState = () => ({
  hostsList: [],
  addHostModalShown: false,
});

const hostsModule = {
  state: initialState(),
  getters: {
    getHosts: state => state.hostsList,
    getHostsReverse: state => [...state.hostsList].reverse(),
    hostModalShown: state => state.addHostModalShown,
  },
  mutations: {
    set(state, { key, val }) {
      state[key] = val;
    },
    ADD_HOST(state, payload) {
      state.hostsList.push(payload);
    },
    hostModalShown(state, val) {
      state.addHostModalShown = val;
    },
    updateListEl(state, el) {
      const targetIndex = state.hostsList.findIndex(
        element => element.id === el.id,
      );
      if (targetIndex > -1) state.hostsList[targetIndex] = el;
      else state.hostsList = [...state.hostsList, el];
    },
    resetHostsState(state) {
      Object.assign(state, initialState());
    },
  },
  actions: {
    async getHosts({ commit }) {
      return axios({
        url: apiUrl('host/'),
        method: 'GET',
      })
        .then((response) => {
          commit('set', { key: 'hostsList', val: response.data });
          return response.data;
        })
        .catch((err) => {
          throw err.response ? err.response.data.error.message : err;
        });
    },
    async addHost({ commit }, data) {
      return axios({
        url: apiUrl('host/'),
        method: 'POST',
        data,
      })
        .then((response) => {
          commit('ADD_HOST', response.data);
          return response.data;
        })
        .catch((err) => {
          throw err.response ? err.response.data.error.message : err;
        });
    },

    async setElua({ commit }, data) {
      const { eula_accepted } = data; // eslint-disable-line

      return axios({
        url: apiUrl(`host/${data.id}/`),
        method: 'PATCH',
        data: { eula_accepted },
      })
        .then((response) => {
          commit('updateListEl', response.data);
          return response.data;
        })
        .catch((err) => {
          throw err.response ? err.response.data.error.message : err;
        });
    },
  },
};

export default hostsModule;
