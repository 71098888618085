var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"custom-select d-inline-flex",class:{
    'custom-select--radiogroup': _vm.type === 'radiogroup',
    'custom-select--select': _vm.type === 'select',
    'custom-select--disabled': _vm.disabled,
  }},[(_vm.type === 'radiogroup')?_c('div',{staticClass:"custom-select__wrapper"},[_vm._l((_vm.options),function(option,index){return [(option.type !== 'range')?_c('button',{key:option.id || index,staticClass:"custom-select__element",class:{
          'custom-select__element--active':
            _vm.selected && _vm.selected.id === option.id,
        },on:{"click":function($event){return _vm.$emit('update:selected', option)}}},[_vm._v(" "+_vm._s(option.label)+" ")]):_vm._e(),(option.type === 'range')?_c('v-date-picker',{key:option.id || index,attrs:{"max-date":new Date(),"dates":new Date(),"mode":"range","popover":{
          visibility: 'hover',
          placement: 'bottom-end',
        },"locale":_vm.$i18n.locale},model:{value:(_vm.range),callback:function ($$v) {_vm.range=$$v},expression:"range"}},[_c('button',{staticClass:"custom-select__element",class:{
            'custom-select__element--active':
              _vm.selected && _vm.selected.id === option.id,
          },on:{"click":function($event){_vm.range && _vm.updateCustomRange(_vm.range)}}},[_vm._v(" "+_vm._s(option.label)+" ")])]):_vm._e()]})],2):_vm._e(),(_vm.type === 'select')?_c('div',{staticClass:"custom-select__wrapper"},[_c('div',{staticClass:"custom-select__selected-value",class:{ 'custom-select__selected-value--focused': _vm.dropdownShown },on:{"click":function($event){_vm.dropdownShown = !_vm.dropdownShown}}},[_vm._v(" "+_vm._s(_vm.selected && _vm.selected.label)+" ")]),_c('div',{staticClass:"custom-select__options-list",class:{ 'custom-select__options-list--shown': _vm.dropdownShown }},[_vm._l((_vm.options),function(option,index){return [_c('div',{key:option.id || index},[(option.type !== 'range')?_c('button',{staticClass:"custom-select__element",class:{
              'custom-select__element--active':
                _vm.selected && _vm.selected.id === option.id,
            },on:{"click":function($event){(_vm.dropdownShown = false), _vm.$emit('update:selected', option)}}},[_vm._v(" "+_vm._s(option.label)+" ")]):_vm._e()])]})],2)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }