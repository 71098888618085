<template>
  <div
    class="custom-select d-inline-flex"
    :class="{
      'custom-select--radiogroup': type === 'radiogroup',
      'custom-select--select': type === 'select',
      'custom-select--disabled': disabled,
    }"
  >
    <div v-if="type === 'radiogroup'" class="custom-select__wrapper">
      <template v-for="(option, index) in options">
        <button
          v-if="option.type !== 'range'"
          :key="option.id || index"
          :class="{
            'custom-select__element--active':
              selected && selected.id === option.id,
          }"
          class="custom-select__element"
          @click="$emit('update:selected', option)"
        >
          {{ option.label }}
        </button>

        <v-date-picker
          v-if="option.type === 'range'"
          v-model="range"
          :key="option.id || index"
          :max-date="new Date()"
          :dates="new Date()"
          mode="range"
          :popover="{
            visibility: 'hover',
            placement: 'bottom-end',
          }"
          :locale="$i18n.locale"
        >
          <button
            :class="{
              'custom-select__element--active':
                selected && selected.id === option.id,
            }"
            @click="range && updateCustomRange(range)"
            class="custom-select__element"
          >
            {{ option.label }}
          </button>
        </v-date-picker>
      </template>
    </div>
    <div v-if="type === 'select'" class="custom-select__wrapper">
      <div
        class="custom-select__selected-value"
        :class="{ 'custom-select__selected-value--focused': dropdownShown }"
        @click="dropdownShown = !dropdownShown"
      >
        {{ selected && selected.label }}
      </div>
      <div
        class="custom-select__options-list"
        :class="{ 'custom-select__options-list--shown': dropdownShown }"
      >
        <template v-for="(option, index) in options">
          <div :key="option.id || index">
            <button
              v-if="option.type !== 'range'"
              :class="{
                'custom-select__element--active':
                  selected && selected.id === option.id,
              }"
              class="custom-select__element"
              @click="(dropdownShown = false), $emit('update:selected', option)"
            >
              {{ option.label }}
            </button>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'CustomSelect',
  props: {
    type: String,
    options: { type: Array, required: true },
    selected: {
      type: Object,
    },
    disabled: Boolean,
  },
  data() {
    return {
      range: null,
      dropdownShown: false,
    };
  },
  methods: {
    updateCustomRange(value) {
      let { start, end } = value;

      // substract 1 day in case of picking range of 0 days
      if (start.getTime() === end.getTime()) {
        start = moment(start).startOf('day').format('YYYY-MM-DD');
      }
      end = moment(end).add(1, 'days').format('YYYY-MM-DD');
      const selected = {
        label: this.$t('calendar.forPeriod'),
        type: 'range',
        value: {
          start: moment(start).format('YYYY-MM-DD'),
          end: moment(end).format('YYYY-MM-DD'),
        },
      };

      this.$emit('update:selected', selected);
    },
  },
  watch: {
    range(val) {
      this.updateCustomRange(val);
    },
  },
  mounted() {
    if (this.type === 'select' && !this.selected) {
      this.$emit('update:selected', this.options[0]);
    }
  },
};
</script>

<style lang="scss">
@import "@/styles/variables";

.custom-select {
  position: relative;
  color: $c-text-primary;
  z-index: 2;

  &--disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &--radiogroup {
    .custom-select {
      &__element {
        padding: 6px 18px;
        background-color: transparent;
        border: 1px solid $c-border-light;
        border-radius: 100px;

        &--active {
          background-color: $c-border-light;
        }

        & + * {
          margin-left: 10px;
        }
      }
    }
  }

  &--select {
    .custom-select {
      &__options-list {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        padding-top: 20px;
        background-color: $c-dropdown-bg;
        border: 1px solid $c-dropdown-border;
        border-radius: 0 0 15px 15px;
        box-shadow: 0px 5px 6px rgba(27, 141, 190, 0.08);

        &:not(.custom-select__options-list--shown) {
          display: none;
        }
      }

      &__wrapper {
        min-width: 150px;
        background-color: $c-dropdown-bg;
        border-radius: 15px;
      }

      &__selected-value {
        position: relative;
        padding: 8px 25px 8px 30px;
        text-align: left;
        background-color: $c-dropdown-bg;
        background-image: url(/icons/carret-light.svg);
        background-repeat: no-repeat;
        background-position: calc(100% - 15px) 50%;
        border: 1px solid $c-dropdown-border;
        border-radius: 18px;
        z-index: 1;
        cursor: pointer;

        &--focused {
          border-color: $c-dropdown-border-active;
          box-shadow: 0 5px 6px rgba(27, 141, 190, 0.08);
        }
      }

      &__element {
        padding: 8px 25px 8px 30px;
        width: 100%;
        text-align: left;
        background: transparent;
        border: none;

        &--active {
          display: none;
        }
      }
    }
  }
}
</style>
