export default {
  install(Vue) {
    function log(type, ...args) {
      // eslint-disable-next-line
      console.log(`[${type}]`, ...args); 
    }

    // eslint-disable-next-line
    Vue.prototype.$log = {
      error(...args) { log('danger', ...args); },
      success(...args) { log('success', ...args); },
      log,
    };
  },
};
