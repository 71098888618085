<template>
  <header class="header col-12">
    <div class="header__caption row align-items-center">
      <div class="col-2">
        <router-link to="dashboard">
          <img :src="logo.url" :alt="logo.alt" />
        </router-link>
      </div>
      <div class="col-3">
        <CustomButton
          :label="$t('main.addHost')"
          icon="add"
          @click="hostModalShown = true"
        />
      </div>
      <YourManager class="col-4" :manager="$store.getters.manager || manager" />
      <div class="col"><UserPlate :username="$store.getters.login" /></div>
    </div>
    <NotificationsCarousel class="header__notifications-carousel" />
    <Modal :shown.sync="hostModalShown">
      <h2 slot="header" class="offset-3">{{ $t('main.addHost') }}</h2>
      <form slot="body" @submit.prevent="submitForm()">
        <div class="row py-3">
          <div class="col-3 align-self-center form-label">{{ $t('main.platform') }}</div>
          <div class="col-9">
            <input type="text" v-model="url" class="form-input" />
          </div>
        </div>
        <div class="row py-3">
          <div class="offset-3 col-9">
            <div class="d-flex">
              <button type="submit" class="form-btn">{{ $t('common.add') }}</button>
            </div>
          </div>
        </div>
      </form>
      <div slot="footer" />
    </Modal>
  </header>
</template>
<script>
import { Notification } from 'element-ui';

import CustomButton from '@/components/controls/CustomButton.vue';
import UserPlate from '@/components/controls/UserPlate.vue';
import YourManager from '@/components/controls/YourManager.vue';
import NotificationsCarousel from '@/components/controls/NotificationsCarousel.vue';
import Modal from '@/components/Modal.vue';

export default {
  name: 'Header',
  components: {
    CustomButton,
    UserPlate,
    YourManager,
    NotificationsCarousel,
    Modal,
  },
  data() {
    return {
      manager: {
        name: 'Александра Рахимова',
        email: 'alexandra.rakh@umedia.group',
      },
      url: '',
      modalShown: false,
    };
  },
  computed: {
    logo() {
      return {
        url: process.env.VUE_APP_LOGO_IMG,
        alt: process.env.VUE_APP_MODE_NAME,
      };
    },
    hostModalShown: {
      set(val) {
        this.$store.commit('hostModalShown', val);
      },
      get() {
        return this.$store.getters.hostModalShown;
      },
    },
  },
  created() {
    this.$store.dispatch('managerRequest');
    this.$store.dispatch('currencyRequest');
  },
  methods: {
    submitForm() {
      const urlexpr = /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi;
      const { url } = this;
      if (!urlexpr.test(url)) {
        Notification.error({
          title: this.$t('notification.error'),
          message: this.$t('notification.errorIncorrectUrl'),
        });
        return;
      }
      this.$store.dispatch('addHost', { url }).then(
        () => {
          Notification.success({
            title: this.$t('notification.success'),
            message: this.$t('notification.successAddHostX', [url]),
          });

          this.$store.commit('hostModalShown', false);
          this.url = '';
        },
        () => {
          Notification.error({
            title: this.$t('notification.error'),
            message: this.$t('notification.errorAddHost'),
          });
        },
      );
    },
  },
};
</script>

<style lang="scss"></style>
